import React from "react";
import Map from "../components/Map/Map";

import "../styles/Home.scss";
import ReportsStats from "../components/ReportsStats";
import useReports from "../shared/hooks/useReports";
import NewsList from "../components/NewsList";
import { DownOutlined } from "@ant-design/icons";
import animateScrollTo from "animated-scroll-to";

const HomePage = () => {
  const { reports } = useReports();

  return (
    <div className="home">
      <div className="home__sidebar home__sidebar--left">
        <ReportsStats reports={reports} />
      </div>
      <div className="home__map">
        <div className="home__logo">
          <img src="img/lid-logo.svg" alt="La Izquierda Diario" />
        </div>
        <Map reports={reports} />
        <button
          className="home__scroll-button"
          onClick={() => {
            animateScrollTo(window.innerHeight, { speed: 300 });
          }}
        >
          <span className="home__scroll-button-title">
            MAS INFORMACIÓN <DownOutlined style={{ marginLeft: 6 }} />
          </span>
        </button>
      </div>
      <div className="home__sidebar home__sidebar--right">
        <NewsList />
      </div>
    </div>
  );
};
export default HomePage;
