import React from "react";
import { Button, Table, Card, Tooltip } from "antd";
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import URLS from "../../../shared/urls";
import useUsers from "../../../shared/hooks/useUsers";

const AdminUsersGrid = () => {
  const { users, isLoadingUsers } = useUsers();

  return (
    <Card
      title="Usuarios"
      extra={
        <Link to={URLS.ADMIN_USERS_NEW}>
          <Button icon={<PlusCircleOutlined />} type="primary">
            Agregar Usuario
          </Button>
        </Link>
      }
    >
      <Table
        loading={isLoadingUsers}
        dataSource={users}
        columns={[
          {
            title: "Nombre de usuario",
            dataIndex: "username",
            key: "username"
          },
          {
            width: 200,
            title: "Acciones",
            key: "actions",
            dataIndex: "_id",
            render(userId) {
              return (
                <>
                  <Tooltip title="Eliminar">
                    <Button
                      style={{ marginRight: 5 }}
                      type="danger"
                      size="small"
                      icon={<DeleteOutlined />}
                      //   onClick={() => removeReport({ reportId })}
                    />
                  </Tooltip>
                  <Tooltip title="Editar">
                    <Link to={URLS.ADMIN_USERS_EDIT.replace(":_id", userId)}>
                      <Button
                        type="default"
                        size="small"
                        icon={<EditOutlined />}
                      />
                    </Link>
                  </Tooltip>
                </>
              );
            }
          }
        ]}
      />
    </Card>
  );
};
export default AdminUsersGrid;
