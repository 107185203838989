import React from "react";

import "../styles/ReportsStats.scss";
import { ReportsList } from "../shared/hooks/useReports";
import useCategories from "../shared/hooks/useCategories";
import { Skeleton } from "antd";
import { orderBy } from "lodash";
import { Category } from "../shared/Category";
import PercentageStats from "./PercentageStat";

interface ReportsStatsProps {
  reports: ReportsList;
}

interface CategoryStat {
  id: string;
  title: string;
  count: number;
}

const getCategoriesStats = ({
  reports,
  categories,
}: {
  reports: ReportsList;
  categories: Category[];
}) => {
  const categoryStats: CategoryStat[] = categories.map((category) => ({
    id: category._id,
    title: category.title,
    count: 0,
  }));
  reports.forEach((report) => {
    const categoryStat = categoryStats.find((c) => c.id === report.categoryId);
    if (categoryStat) {
      categoryStat.count += 1;
    }
  });
  return orderBy(
    categoryStats.filter((c) => c.count),
    "count",
    "desc"
  );
};

const ReportsStats: React.FunctionComponent<ReportsStatsProps> = ({
  reports,
}) => {
  const { categories, isLoadingCategories } = useCategories();

  if (isLoadingCategories || !reports.length) {
    return (
      <div style={{ opacity: 0.6 }}>
        <Skeleton active />
      </div>
    );
  }

  const total = reports.length;

  const categoriesStats = getCategoriesStats({ reports, categories });

  return (
    <div className="reports-stats">
      <span className="reports-stats__main-number">{total}</span>
      <span className="reports-stats__main-label">denuncias</span>

      <hr className="separator" />

      <div>
        {categoriesStats.map(({ id, count, title }) => (
          <PercentageStats
            key={id}
            title={title}
            number={count}
            total={total}
          />
        ))}
      </div>
    </div>
  );
};
export default ReportsStats;
