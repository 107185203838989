import React from "react";
import { Report } from "../../shared/Report";
import { Popup } from "react-map-gl";
import "../../styles/MapPopup.scss";
import useArticleDetails from "../../shared/hooks/useArticleDetails";
import { Spin, Row, Col } from "antd";

import "../../styles/ArticleMini.scss";

interface MapPopup {
  report?: Report;
  onClose: () => void;
}

const MapPopup: React.FunctionComponent<MapPopup> = ({ report, onClose }) => {
  const { articleDetails, isLoadingArticleDetails } = useArticleDetails({
    links: report && report.links,
  });

  if (!report) {
    return <></>;
  }

  const showArticleList = articleDetails.length === report.links.length;

  return (
    <Popup
      tipSize={5}
      anchor="top"
      longitude={report.location.coords.lng}
      latitude={report.location.coords.lat}
      closeOnClick={false}
      onClose={() => onClose()}
      className="map-popup"
      dynamicPosition={false}
    >
      <div className="map-popup__inner">
        <h3 className="map-popup__title">{report.title}</h3>
        <p>{report.description}</p>
        <p>Lugar: {report.location.description}</p>

        {report.links.length && (
          <span className="map-popup__related-title">Notas relacionadas:</span>
        )}

        {isLoadingArticleDetails ? (
          <Spin className="white-svg" />
        ) : (
          <>
            {showArticleList ? (
              <div className="map-popup__related-articles-list">
                {articleDetails.map((article, i) => (
                  <a
                    href={report.links[i]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Row className="article-mini" key={article.id} gutter={10}>
                      <Col span={6}>
                        <img
                          className="article-mini__img"
                          src={article.miniatura.replace("http:", "https:")}
                          alt=""
                        />
                      </Col>
                      <Col span={18}>
                        <h3 className="article-mini__title">
                          {article.titulo}
                        </h3>
                        <p className="article-mini__description">
                          [+] Leer la nota
                        </p>
                      </Col>
                    </Row>
                  </a>
                ))}
              </div>
            ) : (
              <>
                <ul className="map-popup__related-list">
                  {report.links.map((link) => (
                    <li key={link}>
                      <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        )}
      </div>
    </Popup>
  );
};
export default MapPopup;
