import React, { useState, ReactText } from "react";
import { Modal, Table, Button, Input, Tooltip, Tag } from "antd";
import useLatestArticles from "../../shared/hooks/useLatestArticles";
import { Link } from "react-router-dom";
import URLS from "../../shared/urls";
import { EyeOutlined, PlusOutlined } from "@ant-design/icons";

interface ArticleSelectorProps {
  visible: boolean;
  onClose: () => void;
}

const ArticleSelector: React.FunctionComponent<ArticleSelectorProps> = ({
  visible,
  onClose
}) => {
  const { articles, isLoadingArticles } = useLatestArticles();
  const [searchStr, setSearchStr] = useState<string>();

  return (
    <Modal visible={visible} onCancel={onClose} width={1200}>
      <p>Seleccion entre las últimas notas de LID:</p>

      <Input
        placeholder="Buscar por nombre"
        style={{ marginBottom: 20, marginTop: 10 }}
        value={searchStr}
        onChange={e => {
          setSearchStr(e.target.value);
        }}
      />
      <Table
        dataSource={articles}
        loading={isLoadingArticles}
        rowKey="id"
        columns={[
          {
            title: "Título",
            dataIndex: "title",
            key: "title",
            filteredValue: [searchStr as ReactText],
            onFilter: (value, record) => {
              if (!value) {
                return true;
              }

              const valueStr = value as string;
              return record.title
                .toLowerCase()
                .includes(valueStr.toLowerCase());
            },
            render(title, record) {
              return (
                <>
                  {!!record.usedInReportIds.length && (
                    <Tooltip
                      title={
                        <>
                          <span>
                            Esta nota fue usada como referencia en las
                            siguientes denuncias:
                          </span>
                          <ul style={{ paddingLeft: 13 }}>
                            {record.usedInReportIds.map(report => (
                              <li key={report._id}>
                                <a
                                  href={URLS.ADMIN_REPORTS_EDIT.replace(
                                    ":_id",
                                    report._id
                                  )}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  {report.title}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </>
                      }
                    >
                      <Tag color="green">Cargado</Tag>
                    </Tooltip>
                  )}
                  <span>{title}</span>
                </>
              );
            }
          },
          {
            width: 110,
            title: "Acciones",
            key: "actions",
            dataIndex: "id",
            render(_, article) {
              return (
                <div>
                  <Link
                    to={{
                      pathname: URLS.ADMIN_REPORTS_NEW_AUTO,
                      state: {
                        defaultValues: {
                          title: article.title,
                          links: [article.link],
                          description: article.description
                        }
                      }
                    }}
                  >
                    <Tooltip title="Seleccionar">
                      <Button
                        type="primary"
                        style={{ marginRight: 5 }}
                        icon={<PlusOutlined />}
                      />
                    </Tooltip>
                  </Link>
                  <Tooltip title="Abrir articulo en una nueva pestaña">
                    <Button
                      type="default"
                      href={article.link}
                      target="_blank"
                      icon={<EyeOutlined />}
                    />
                  </Tooltip>
                </div>
              );
            }
          }
        ]}
      />
    </Modal>
  );
};
export default ArticleSelector;
