import React, { useState } from "react";
import { Card, Button, Row, Col } from "antd";
import { Link } from "react-router-dom";
import URLS from "../../../shared/urls";
import BackCardTitle from "../../BackCardTitle";
import ArticleSelector from "../ArticleSelector";

const AdminReportsNew = () => {
  const [isArticleSelectorOpen, setIsArticleSelectorOpen] = useState<boolean>(
    false
  );

  const onModalClose = () => setIsArticleSelectorOpen(false);

  return (
    <>
      <Card title={<BackCardTitle title="Agregar denuncia" />}>
        <Row gutter={20}>
          <Col md={{ span: 8, offset: 4 }} style={{ marginBottom: 10 }}>
            <Card title="Carga manual" type="inner">
              <p>
                Cargar una denuncia manualmente, puede estar acompañada de una
                nota de LID o no.
                <br />
                <br />
              </p>
              <div style={{ textAlign: "center" }}>
                <Link to={URLS.ADMIN_REPORTS_NEW_MANUAL}>
                  <Button type="primary">Cargar manualmente</Button>
                </Link>
              </div>
            </Card>
          </Col>
          <Col md={{ span: 8 }}>
            <Card title="Carga desde LID" type="inner">
              <p>
                Si ya existe una nota de LID reciente sobre esta denuncia podés
                usar esta opción para agilizar la carga
              </p>
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={() => setIsArticleSelectorOpen(true)}
                  type="primary"
                >
                  Cargar desde LID
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Card>
      <ArticleSelector visible={isArticleSelectorOpen} onClose={onModalClose} />
    </>
  );
};
export default AdminReportsNew;
