import React from "react";
import { Switch, Route } from "react-router-dom";
import { Layout } from "antd";
import URLS from "../shared/urls";
import AdminHomePage from "../components/Admin/AdminHomePage";
import AdminHeader from "../components/Admin/AdminHeader";
import AdminReportsGrid from "../components/Admin/AdminReports/AdminReportsGrid";
import AdminReportsDetail from "../components/Admin/AdminReports/AdminReportsDetail";
import AdminCategoriesGrid from "../components/Admin/AdminCategories/AdminCategoriesGrid";
import AdminCategoriesDetail from "../components/Admin/AdminCategories/AdminCategoriesDetail";
import AdminReportsNew from "../components/Admin/AdminReports/AdminReportsNew";
import AdminUsersGrid from "../components/Admin/AdminUsers/AdminUsersGrid";
import AdminUsersDetail from "../components/Admin/AdminUsers/AdminUsersDetail";

const AdminPage = () => (
  <Layout>
    <Layout.Header>
      <div className="container">
        <AdminHeader />
      </div>
    </Layout.Header>
    <Layout.Content style={{ paddingTop: 20 }}>
      <div className="container">
        <Switch>
          <Route exact path={URLS.ADMIN} component={AdminHomePage} />

          {/* Users */}
          <Route exact path={URLS.ADMIN_USERS} component={AdminUsersGrid} />
          <Route
            exact
            path={URLS.ADMIN_USERS_NEW}
            component={AdminUsersDetail}
          />
          <Route
            exact
            path={URLS.ADMIN_USERS_EDIT}
            component={AdminUsersDetail}
          />

          {/* Reports */}
          <Route exact path={URLS.ADMIN_REPORTS} component={AdminReportsGrid} />
          <Route
            exact
            path={URLS.ADMIN_REPORTS_NEW}
            component={AdminReportsNew}
          />
          <Route
            exact
            path={URLS.ADMIN_REPORTS_NEW_AUTO}
            component={AdminReportsDetail}
          />
          <Route
            exact
            path={URLS.ADMIN_REPORTS_NEW_MANUAL}
            component={AdminReportsDetail}
          />
          <Route
            exact
            path={URLS.ADMIN_REPORTS_EDIT}
            component={AdminReportsDetail}
          />

          {/* Categories */}
          <Route
            exact
            path={URLS.ADMIN_CATEGORIES}
            component={AdminCategoriesGrid}
          />
          <Route
            exact
            path={URLS.ADMIN_CATEGORIES_NEW}
            component={AdminCategoriesDetail}
          />
          <Route
            exact
            path={URLS.ADMIN_CATEGORIES_EDIT}
            component={AdminCategoriesDetail}
          />
        </Switch>
      </div>
    </Layout.Content>
  </Layout>
);

export default AdminPage;
