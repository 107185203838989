import React, { useState } from "react";
import moment from "moment";
import Media from "react-media";
import { Button, Table, Card, Tooltip, message, Input } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import URLS from "../../../shared/urls";
import useReports from "../../../shared/hooks/useReports";
import FetchService from "../../../shared/fetchService";
import ApiEndpoints from "../../../shared/ApiEndpoints";
import { DATE_TIME_FORMAT } from "../../../shared/config";

const AdminReportsGrid = () => {
  const [searchFilter, setSearchFilter] = useState<string>();
  const { isLoadingReports, reports, fetchReports } = useReports({
    searchFilter
  });

  const removeReport = async ({ reportId }: { reportId: string }) => {
    if (window.confirm("Esta seguro de eliminar esta denuncia?")) {
      const closeLoadingMessage = message.loading("Borrando reporte...");
      await FetchService.request(ApiEndpoints.REPORTS_REMOVE, {
        body: JSON.stringify({ reportId })
      });
      fetchReports();
      closeLoadingMessage();
    }
  };

  const columns = [
    {
      title: "Título",
      dataIndex: "title",
      key: "title"
    },
    {
      title: "Categoría",
      dataIndex: "categoryTitle",
      key: "categoryTitle",
      hideOnSmall: true
    },
    {
      width: 200,
      title: "Fecha creación",
      dataIndex: "createdAt",
      key: "createdAt",
      render(createdAt: Date) {
        return createdAt ? moment(createdAt).format(DATE_TIME_FORMAT) : "N/A";
      },
      hideOnSmall: true
    },
    {
      title: "Acciones",
      key: "actions",
      dataIndex: "_id",
      width: 90,
      render(reportId: string) {
        return (
          <>
            <Tooltip title="Eliminar">
              <Button
                style={{ marginRight: 5 }}
                type="danger"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => removeReport({ reportId })}
              />
            </Tooltip>
            <Tooltip title="Editar">
              <Link to={URLS.ADMIN_REPORTS_EDIT.replace(":_id", reportId)}>
                <Button type="default" size="small" icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          </>
        );
      }
    }
  ];

  const getResponsiveColumns = (smallScreen: boolean) =>
    columns.filter(({ hideOnSmall = false }) => !(smallScreen && hideOnSmall));

  return (
    <Card
      title="Denuncias"
      extra={
        <Link to={URLS.ADMIN_REPORTS_NEW}>
          <Button icon={<PlusCircleOutlined />} type="primary">
            Agregar Denuncia
          </Button>
        </Link>
      }
    >
      <div style={{ marginBottom: 20 }}>
        <Input.Search
          placeholder="Buscar..."
          enterButton={<SearchOutlined />}
          onSearch={searchStr => setSearchFilter(searchStr)}
        />
      </div>
      <Media query="(max-width: 768px)">
        {smallScreen => (
          <Table
            loading={isLoadingReports}
            dataSource={reports}
            rowKey="_id"
            columns={getResponsiveColumns(smallScreen)}
          />
        )}
      </Media>
    </Card>
  );
};
export default AdminReportsGrid;
