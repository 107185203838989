import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import URLS from "../../shared/urls";
import AuthService from "../../shared/AuthService";
import { RolesEnum } from "../../shared/roles";

const AdminHeader = () => {
  const role = AuthService.getCurrentRole();

  return (
    <div>
      <Menu
        theme="dark"
        mode="horizontal"
        style={{ lineHeight: "64px" }}
        className="header-menu"
      >
        <Menu.Item>
          <Link to={URLS.ADMIN_REPORTS}>Denuncias</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={URLS.ADMIN_CATEGORIES}>Categorías</Link>
        </Menu.Item>
        {role === RolesEnum.ADMIN && (
          <Menu.Item>
            <Link to={URLS.ADMIN_USERS}>Usuarios</Link>
          </Menu.Item>
        )}
        <Menu.Item className="header-menu__right">
          <Link
            onClick={() => localStorage.removeItem("token")}
            to={URLS.LOGIN}
          >
            Salir
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};
export default AdminHeader;
