import { useState, useEffect } from "react";
import FetchService from "../fetchService";
import ApiEndpoints from "../ApiEndpoints";
import { Report } from "../Report";

export type ReportsList = (Report & { categoryTitle: string })[];

interface useReportsParams {
  searchFilter?: string;
}

const useReports = (params: useReportsParams = {}) => {
  const { searchFilter } = params;

  const [isLoadingReports, setIsLoadingReports] = useState(true);
  const [reports, setReports] = useState<ReportsList>([]);

  const fetchReports = async () => {
    setIsLoadingReports(true);
    const { reports } = await FetchService.request(ApiEndpoints.REPORTS_LIST, {
      body: JSON.stringify({ searchFilter }),
    });
    setReports(reports);
    setIsLoadingReports(false);
  };

  const fetchReportsWrap = () => {
    fetchReports();
  };

  useEffect(fetchReportsWrap, [searchFilter]);
  return { isLoadingReports, reports, fetchReports };
};
export default useReports;
