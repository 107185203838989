import React, { useState } from "react";
import ReactMapGL, {
  NavigationControl,
  ScaleControl,
  FlyToInterpolator,
  InteractiveMapProps,
} from "react-map-gl";
import { MAP_API_URL } from "../../shared/config";
import { ReportsList } from "../../shared/hooks/useReports";
import Pins from "./Pins";
import { Report } from "../../shared/Report";
import MapPopup from "./MapPopup";
import Media from "react-media";

interface MapProps {
  reports: ReportsList;
}

const Map: React.FunctionComponent<MapProps> = ({ reports }) => {
  const defaultViewport: InteractiveMapProps = {
    width: window.innerWidth,
    height: window.innerHeight,
    latitude: -34.7664407,
    longitude: -58.3608809,
    zoom: 9,
  };
  const [viewport, setViewport] = useState(defaultViewport);

  const [expandedReport, setExpandedReport] = useState<Report>();
  const onPinClick = (report: Report, moveMap: boolean) => {
    setExpandedReport(report);
    if (moveMap) {
      setViewport({
        ...viewport,
        longitude: report.location.coords.lng,
        transitionInterpolator: new FlyToInterpolator({
          speed: 0.5,
        }),
        transitionDuration: "auto",
      });
    }
  };
  const onCloseExpandedReport = () => {
    setExpandedReport(undefined);
  };

  window.onresize = () => {
    setViewport({
      ...viewport,
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  return (
    <div className="responsive-container">
      <ReactMapGL
        {...viewport}
        onViewportChange={(viewport) => setViewport(viewport)}
        mapStyle={MAP_API_URL}
      >
        <Media query="(max-width: 768px)">
          {(smallScreen) => (
            <Pins
              reports={reports}
              onClick={(report) => {
                onPinClick(report, smallScreen);
              }}
            />
          )}
        </Media>

        <MapPopup report={expandedReport} onClose={onCloseExpandedReport} />

        <div
          className="hide-mobile"
          style={{
            position: "absolute",
            top: 36,
            right: 0,
            padding: "10px",
          }}
        >
          <NavigationControl />
        </div>
        <div
          className="hide-mobile"
          style={{
            position: "absolute",
            bottom: 5,
            left: 0,
            padding: "10px",
          }}
        >
          <ScaleControl />
        </div>
      </ReactMapGL>
    </div>
  );
};
export default Map;
