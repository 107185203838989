import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Button,
  message,
  Spin,
  Select
} from "antd";
import { get } from "lodash";
import BackCardTitle from "../../BackCardTitle";
import FetchService from "../../../shared/fetchService";
import ApiEndpoints from "../../../shared/ApiEndpoints";
import { RouteComponentProps, withRouter, useLocation } from "react-router-dom";
import { Report } from "../../../shared/Report";
import URLS from "../../../shared/urls";
import useCategories from "../../../shared/hooks/useCategories";
import {
  MinusCircleOutlined,
  PlusOutlined,
  ArrowUpOutlined
} from "@ant-design/icons";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import PlaceSelector from "../../PlaceSelector";

interface ReportFormValues {
  title: string;
  description: string;
  location: {
    description: string;
    place_id: string;
    coords: {
      lat: number;
      lng: number;
    };
  };
  categoryId: string;
  source: string;
  links: string[];
}

interface AdminReportsDetailRouteParams {
  _id?: string;
}

const AdminReportsDetail: React.FunctionComponent<RouteComponentProps<
  AdminReportsDetailRouteParams
>> = ({ match, history }) => {
  const location = useLocation();
  const [locationStateDefaultValues] = useState(
    get(location, "state.defaultValues", undefined)
  );

  const isEditing = !!match.params && match.params._id;
  const reportId = isEditing && match.params._id;

  const { categories, isLoadingCategories } = useCategories();

  const [form] = Form.useForm();

  const [isSending, setIsSending] = useState(false);
  const saveForm = async (values: any) => {
    setIsSending(true);
    try {
      const formValues: ReportFormValues = values;
      formValues.links = formValues.links.filter(Boolean);

      // Convert selected place to coordinates
      const results = await getGeocode({
        placeId: formValues.location.place_id
      });
      const coords = await getLatLng(results[0]);

      const { location } = formValues;

      const reportValues = {
        ...formValues,
        location: {
          place_id: location.place_id,
          description: location.description,
          coords
        }
      };

      const endpoint = isEditing
        ? ApiEndpoints.REPORTS_EDIT
        : ApiEndpoints.REPORTS_ADD;
      await FetchService.request(endpoint, {
        body: JSON.stringify({
          reportValues,
          reportId
        })
      });
      form.resetFields();
      message.success(isEditing ? "Denuncia actualizada" : "Denuncia guardada");

      if (isEditing) {
        history.push(URLS.ADMIN_REPORTS);
      }
      if (locationStateDefaultValues) {
        history.push(URLS.ADMIN_REPORTS_NEW);
      }
    } catch (e) {
      message.error(e);
    } finally {
      setIsSending(false);
    }
  };

  const [report, setReport] = useState<Report>();
  const [isLoadingReport, setIsLoadingReport] = useState(true);

  useEffect(() => {
    const fetchReport = async () => {
      setIsLoadingReport(true);
      const { report } = await FetchService.request(
        ApiEndpoints.REPORTS_DETAIL,
        {
          body: JSON.stringify({ reportId })
        }
      );
      setReport(report);
      setIsLoadingReport(false);
    };

    if (isEditing) {
      fetchReport();
    }
  }, [isEditing, reportId]);

  const isLoading = isEditing && isLoadingReport;

  if (isLoading) {
    return <Spin />;
  }

  let initialValues: Partial<ReportFormValues> = {
    links: [""]
  };

  if (report && isEditing) {
    initialValues = {
      categoryId: report.categoryId,
      description: report.description,
      location: report.location,
      source: report.source,
      title: report.title,
      links: report.links.length ? report.links : [""]
    };
  }

  if (locationStateDefaultValues) {
    const hasLinks =
      locationStateDefaultValues.links &&
      locationStateDefaultValues.links.length;

    initialValues = {
      source: `Nota LID: ${hasLinks && locationStateDefaultValues.links[0]}`,
      title: locationStateDefaultValues.title,
      links: hasLinks ? locationStateDefaultValues.links : [""],
      description: locationStateDefaultValues.description
    };
  }

  return (
    <Card title={<BackCardTitle title="Agregar denuncia" />}>
      <Row>
        <Col md={{ span: 16, offset: 4 }}>
          <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={saveForm}
            initialValues={initialValues}
          >
            <Form.Item label="Título" name="title" rules={[{ required: true }]}>
              <Input autoFocus />
            </Form.Item>

            <Form.Item
              label="Descripción"
              name="description"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label="Categoría"
              name="categoryId"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                loading={isLoadingCategories}
                showSearch
                optionFilterProp="children"
              >
                {categories.map(category => (
                  <Select.Option value={category._id}>
                    {category.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Lugar"
              name="location"
              rules={[{ required: true }]}
            >
              <PlaceSelector />
            </Form.Item>

            <Form.Item
              label="Fuente"
              name="source"
              style={{ marginBottom: 10 }}
              help={
                <>
                  <ArrowUpOutlined style={{ marginRight: 5 }} />
                  <span>
                    Campo opcional. Por razones de seguridad no ingrese
                    información sobre la persona que hizo el reporte.
                  </span>
                </>
              }
            >
              <Input.TextArea />
            </Form.Item>

            <Form.List name="links">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <Form.Item
                        label={`Link LID ${index + 1}`}
                        required={false}
                        key={field.key}
                        style={{ marginBottom: 10 }}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={["onChange", "onBlur"]}
                          noStyle
                        >
                          <Input
                            type="url"
                            placeholder="ej: https://www.laizquierdadiario.com/Rescates-para-los-ricos-virus-para-el-resto-de-nosotros"
                            style={{ marginRight: 8 }}
                          />
                        </Form.Item>

                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : (
                          <></> // IDK
                        )}
                      </Form.Item>
                    ))}
                    <Form.Item wrapperCol={{ md: { offset: 4 } }}>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        disabled={fields.length >= 3}
                      >
                        <PlusOutlined /> Agregar más links LID
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>

            <div style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isSending}
                loading={isSending}
              >
                Guardar
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};
export default withRouter(AdminReportsDetail);
