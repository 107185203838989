const URLS = {
  HOME: "/",
  LOGIN: "/login",
  ADMIN: "/admin",
  ADMIN_USERS: "/admin/usuarios",
  ADMIN_USERS_NEW: "/admin/usuarios/nuevo",
  ADMIN_USERS_EDIT: "/admin/usuarios/editar/:_id",
  ADMIN_REPORTS: "/admin/denuncias",
  ADMIN_REPORTS_NEW: "/admin/denuncias/nuevo",
  ADMIN_REPORTS_NEW_MANUAL: "/admin/denuncias/nuevo/manual",
  ADMIN_REPORTS_NEW_AUTO: "/admin/denuncias/nuevo/auto",
  ADMIN_REPORTS_EDIT: "/admin/denuncias/editar/:_id",
  ADMIN_CATEGORIES: "/admin/categorias",
  ADMIN_CATEGORIES_NEW: "/admin/categorias/nuevo",
  ADMIN_CATEGORIES_EDIT: "/admin/categorias/editar/:_id"
};
export default URLS;
