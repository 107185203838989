const ApiEndpoints = {
  LOGIN: "auth/login",
  REPORTS_LIST: "reports/list",
  REPORTS_DETAIL: "reports/detail",
  REPORTS_ADD: "reports/add",
  REPORTS_EDIT: "reports/edit",
  REPORTS_REMOVE: "reports/remove",
  CATEGORIES_LIST: "categories/list",
  CATEGORIES_DETAIL: "categories/detail",
  CATEGORIES_ADD: "categories/add",
  CATEGORIES_EDIT: "categories/edit",
  ARTICLES_GET_LATEST: "articles/getLatest",
  ARTICLES_GET_FEATURED: "articles/getFeatured",
  ARTICLES_GET_DETAILS: "articles/getDetails",
  USERS_LIST: "users/list",
  USERS_DETAIL: "users/detail",
  USERS_ADD: "users/add",
  USERS_EDIT: "users/edit",
};
export default ApiEndpoints;
