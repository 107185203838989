import React from "react";
import { Select } from "antd";
import usePlacesAutocomplete from "use-places-autocomplete";

const PlaceSelector = ({ value, onChange }: any) => {
  const {
    value: searchValue,
    suggestions: { data: suggestionOptions },
    setValue: setSearchValue
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: "ar"
      }
    },
    debounce: 300
  });

  const handleSearch = (searchStr: string) => {
    setSearchValue(searchStr);
  };

  const handleChange = (place_id: string) => {
    const selectedSuggestion = suggestionOptions.find(
      s => s.place_id === place_id
    );
    onChange(selectedSuggestion);
  };

  let options = suggestionOptions;
  if (searchValue === "" && value) {
    options = [value];
  }

  return (
    <Select
      showSearch
      value={value?.place_id}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
    >
      {options.map(({ place_id, description }) => (
        <Select.Option value={place_id} key={place_id}>
          {description}
        </Select.Option>
      ))}
    </Select>
  );
};
export default PlaceSelector;
